// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-en-jsx": () => import("./../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-ko-jsx": () => import("./../src/pages/index.ko.jsx" /* webpackChunkName: "component---src-pages-index-ko-jsx" */),
  "component---src-pages-index-pt-jsx": () => import("./../src/pages/index.pt.jsx" /* webpackChunkName: "component---src-pages-index-pt-jsx" */)
}

