/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('./static/fonts.css')

exports.onClientEntry = () => {
  console.log('--------------------------------------------')
  console.log('%c                    Made by', 'text-align: center; color: #006cff;font-size: 18px; font-family: "Roboto", sans-serif')
  console.log('%cInvisual Branding Solutions', 'color: white; font-size: 24px; font-family: "Roboto", sans-serif')
  console.log('--------------------------------------------')
  const userLang = navigator.language.substr(0, 2)

  if (userLang === "pt" && !window.location.pathname.startsWith("/pt") && !window.location.pathname.startsWith("/en") && !window.location.pathname.startsWith("/ko")) {
    window.location.pathname = `/pt${window.location.pathname}`
  } else if (userLang === "en" && !window.location.pathname.startsWith("/pt") && !window.location.pathname.startsWith("/en") && !window.location.pathname.startsWith("/ko")) {
    window.location.pathname = `/en${window.location.pathname}`
  } else if (userLang === "ko" && !window.location.pathname.startsWith("/pt") && !window.location.pathname.startsWith("/en") && !window.location.pathname.startsWith("/ko")) {
    window.location.pathname = `/ko${window.location.pathname}`
  } else if (userLang !== "pt" && userLang !== "en" && userLang !== "es" && userLang !== "ko" && !window.location.pathname.startsWith("/pt") && !window.location.pathname.startsWith("/en") && !window.location.pathname.startsWith("/ko")) {
    window.location.pathname = `/en${window.location.pathname}`
  }
}